import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ReactElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Button from "@/components/Buttons/Button";
import ContentHeader from "@/components/ContentHeader";
import { PaddedPlatformLogo } from "@/components/PlatformLogo";
import { OngoingConnectivityChoiceProps } from "@/components/SyncMessages/SyncResultsAndSubUserDecision";
import SyncResultSummary from "@/components/SyncResultSummary";
import { useWebSocketQueue } from "@/contexts/WebSocketQueueContext";
import { SubuserChoiceStatus } from "@/interfaces";
import { ChoiceEventPayload, SyncEventType, UserDecisionType } from "@/models/event";
import { SyncEventMessage } from "@/models/message";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useClientStore from "@/store/client";
import useSyncStore from "@/store/sync";
import { UUID } from "@/types";

function OngoingConnectivityStart({
  setSubuserChoice,
  subuserChoice,
  deadlineIndicator,
  disabled = false,
}: OngoingConnectivityChoiceProps): ReactElement {
  const { platformConfig: platform, sync_target_id } = useSyncStore();
  if (platform == null) throw Error("unexpected null platform");

  const {
    config: { customer_short_name },
  } = useClientStore();
  const { sendMessage, syncTargetId, setIsWaiting, signalReadyToAdvanceQueue } = useWebSocketQueue();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const onOptIn = () => {
    const payload = {
      eventType: SyncEventType.Choice,
      eventId: uuidv4() as UUID,
      choice: true,
      decisionType: UserDecisionType.CreateSubUser,
    } as ChoiceEventPayload;
    const message = new SyncEventMessage({ messageId: uuidv4() as UUID, syncTargetId, payload });

    setSubmitted(true);
    sendMessage(message);
    setSubuserChoice(SubuserChoiceStatus.OptedIn);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  return (
    <div className="flex h-full w-full flex-col items-center">
      <ContentBody>
        <div className="flex items-center space-x-2 md:space-x-3">
          <CheckCircleIcon className="h-6 w-6 text-green-500 md:h-10 md:w-10" />
          <ContentHeader title="Connection Successful!" />
        </div>
        {sync_target_id && (
          <div className="mt-6 md:mt-10">
            <SyncResultSummary
              syncTargetId={sync_target_id}
              hideOngoingConnectivity
            />
          </div>
        )}
        <div className="mt-6 md:mt-10">
          <div className="text-md flex flex-col gap-y-4 text-navy-500 md:gap-y-5 md:text-lg">
            <p>{customer_short_name} needs up-to-date account data.</p>
            <p>
              Enabling updates configures read-only access to your account. You can always remove this access on your
              bank’s website.
            </p>
          </div>
        </div>
        <PaddedPlatformLogo
          logo={platform.logo}
          displayName={platform.display_name}
        />
      </ContentBody>
      <ContentFooter>
        {deadlineIndicator}
        <div className="flex w-full flex-col gap-y-2 md:gap-y-4">
          <Button
            primary
            fullWidth
            label="Enable updates"
            onClick={() => onOptIn()}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.OptedIn}
          />
          <Button
            fullWidth
            label="Do not enable updates"
            onClick={() => {
              setSubmitted(true);
              setSubuserChoice(SubuserChoiceStatus.Advisory);
            }}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.Advisory}
          />
        </div>
      </ContentFooter>
    </div>
  );
}

export default OngoingConnectivityStart;
