import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import { ReactElement } from "react";

import GoBackButton from "@/components/Buttons/GoBackButton";
import ContentHeader from "@/components/ContentHeader";
import useStateNavigation from "@/hooks/useStateNavigate";

interface GlobalErrorScreenProps {
  titleText: string;
  body: ReactElement;
  icon?: ReactElement;
  showContact?: boolean;
  showGoBack?: boolean;
}

function GlobalErrorScreen({ titleText, body, icon, showContact, showGoBack }: GlobalErrorScreenProps): ReactElement {
  const { navigateBack } = useStateNavigation();

  return (
    <div className="flex flex-col items-center space-y-8">
      <div className="flex w-full flex-row items-center justify-center space-x-2">
        {icon ? icon : <ExclamationCircleIcon className="h-10 w-10 fill-red-500 stroke-white" />}
        <ContentHeader title={titleText} />
      </div>
      {body}
      {showContact && (
        <div>
          {" "}
          If the issue persists,{" "}
          <a
            href={"mailto:support@usebasis.co"}
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-2 hover:text-gray-700"
          >
            contact us
          </a>
          .
        </div>
      )}
      {showGoBack && <GoBackButton onClick={() => navigateBack()} />}
    </div>
  );
}

export default GlobalErrorScreen;
