import { XMarkIcon } from "@heroicons/react/24/outline";
import { AnimatePresence, motion } from "motion/react";

import AboutBasis from "@/components/AboutBasis";
import BasisLogo from "@/components/BasisLogo";
import ServiceInfo from "@/components/ServiceInfo";
import ValueProposition from "@/components/ValueProposition";
import { useCopy } from "@/hooks/useCopy";
import ContentBody from "@/pages/template/ContentBody";

function MobileInformationDrawerContent({ onClose }: { onClose: () => void }) {
  const { landServiceInfo, landSubtitle } = useCopy();

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="flex h-12 w-full flex-row items-center justify-end px-6"
        onClick={onClose}
      >
        <XMarkIcon className="h-6 w-6 stroke-neutral-900" />
      </div>
      <div className="mx-6">
        <ContentBody>
          <ValueProposition
            landSubtitle={landSubtitle}
            landBody={""}
          />
          <div className="mb-2 mt-4">
            <ServiceInfo content={landServiceInfo} />
          </div>
        </ContentBody>
        <div className="flex flex-row justify-between border-t-1 border-neutral-900 pb-6 pt-6">
          <BasisLogo size="lg" />
          <AboutBasis alignment="right" />
        </div>
      </div>
    </div>
  );
}

interface MobileInformationDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

function MobileInformationDrawer({ isOpen, onClose }: MobileInformationDrawerProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50"
          variants={{
            initial: { opacity: 0 },
            animate: { opacity: 1, transition: { duration: 0.25 } },
            exit: { opacity: 0, transition: { duration: 0.25 } },
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={onClose}
        >
          <motion.div
            className="absolute bottom-0 z-10 h-fit max-h-[80%] w-full bg-accent-fill-muted"
            variants={{
              initial: { y: "100%", opacity: 0 },
              animate: { y: "0%", opacity: 1, transition: { duration: 0.25 } },
              exit: { y: "100%", opacity: 0, transition: { duration: 0.25 } },
            }}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <MobileInformationDrawerContent onClose={onClose} />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default MobileInformationDrawer;
