import { z } from "zod";

export const oAuthInitializeResponseSchema = z.object({
  client_id: z.string().uuid(),
  profile_id: z.string().uuid(),
  platform_id: z.union([z.string(), z.null()]).optional(),
  is_authorized: z
    .boolean()
    .default(false)
    .describe("True if the client is already authorized for the profile.")
    .optional(),
});
export type OAuthInitializeResponseSchema = z.infer<typeof oAuthInitializeResponseSchema>;
