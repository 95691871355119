import { PlusIcon } from "@heroicons/react/24/outline";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import Button from "@/components/Buttons/Button";
import CloseWarning from "@/components/CloseWarning";
import ContentHeader from "@/components/ContentHeader";
import SyncResultSummary from "@/components/SyncResultSummary";
import { useBackButton } from "@/contexts/BackButtonContext";
import { useGetSyncResults } from "@/hooks/useApi";
import { useCopy } from "@/hooks/useCopy";
import useStateNavigation from "@/hooks/useStateNavigate";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useAuthStore from "@/store/auth";
import { InitVersion, handleBackToClient } from "@/utils";

export default function Summary(): ReactElement {
  const redirectSeconds = 8;

  const { oauthParams } = useAuthStore();
  const { summaryTitle } = useCopy();
  const { navigateNext } = useStateNavigation();
  const { setBackButtonControl } = useBackButton();

  useEffect(() => {
    setBackButtonControl({ showReturnToClient: false, showBackButton: false, goBackCallback: () => null });
  }, [setBackButtonControl]);

  const [loading, setLoading] = useState<boolean>(false);
  const [closeWarningEnabled, setCloseWarningEnabled] = useState<boolean>(true);
  const [secondsUntilRedirect, setSecondsUntilRedirect] = useState<number>(redirectSeconds);

  const { data: results, isPending: resultsPending } = useGetSyncResults();

  const shouldRedirect = useMemo(() => {
    return (
      !resultsPending &&
      results?.find((r) => r.accounts.length > 0 || r.subuser_request != null || r.subuser_authorization != null) !=
        null
    );
  }, [results, resultsPending]);

  const onSubmit = useCallback(async () => {
    setCloseWarningEnabled(false);
    setLoading(true);
    const version = oauthParams.initToken ? InitVersion.V1 : InitVersion.V2;
    await handleBackToClient(true, oauthParams.redirectUri, oauthParams.state, version);
    setLoading(false);
  }, [setCloseWarningEnabled, oauthParams]);

  useEffect(() => {
    if (!shouldRedirect) return;

    if (secondsUntilRedirect <= -1) {
      void onSubmit();
      return;
    }

    const interval = setInterval(() => {
      setSecondsUntilRedirect((secondsUntilRedirect) => secondsUntilRedirect - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsUntilRedirect, setSecondsUntilRedirect, shouldRedirect, onSubmit]);

  const widthStyle = {
    width: `${Math.min((redirectSeconds - secondsUntilRedirect) / redirectSeconds, 1) * 100}%`,
  };

  const opacityStyle = {
    opacity: shouldRedirect && secondsUntilRedirect <= redirectSeconds ? 1 : 0,
  };

  return (
    <>
      <ContentBody>
        <ContentHeader title={summaryTitle} />
        <div className="mb-6 mt-6 flex w-full md:mt-10">
          <SyncResultSummary />
        </div>
        <Button
          fullWidth
          onClick={() => navigateNext("/platforms")}
          icon={<PlusIcon className="h-5 w-5" />}
          label="Add another account"
        />
      </ContentBody>
      <ContentFooter>
        <div>
          <Button
            fullWidth
            primary
            label="Finish"
            onClick={() => onSubmit()}
            loading={loading}
          />
          <div
            style={opacityStyle}
            className={"mt-1 h-1 border-none bg-accent-fill-muted duration-1000 ease-in-out"}
          >
            <div
              style={{ ...opacityStyle, ...widthStyle }}
              className={"h-full bg-accent-fill transition-all duration-1000 ease-linear"}
            />
          </div>
        </div>
        <CloseWarning enabled={closeWarningEnabled} />
      </ContentFooter>
    </>
  );
}
