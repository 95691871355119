import { motion } from "motion/react";
import { ReactElement, useEffect, useState } from "react";

import LoadingSpinner, { LoadingSpinnerProps } from "@/components/Suspense/LoadingSpinner";
import { motionProps } from "@/constants/constants";

interface SyncLoadingSpinnerProps extends LoadingSpinnerProps {
  platformName: string;
}

function SyncLoadingSpinner({ platformName, ...baseProps }: SyncLoadingSpinnerProps): ReactElement {
  const [showText, setShowText] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center">
      <LoadingSpinner {...baseProps} />
      {showText && (
        <motion.div
          {...motionProps}
          key={showText ? "text" : "no-text"}
          className="flex flex-col items-center"
        >
          <div className="mt-6 flex flex-col items-center">
            <span>We are communicating with</span>
            <span>{platformName}.</span>
          </div>
          <div className="mt-6">
            <span>This may take several minutes.</span>
          </div>
          <div className="mt-6">
            <span className="font-bold">Do not close this tab.</span>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default SyncLoadingSpinner;
