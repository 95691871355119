import { ReactElement } from "react";

type ContentHeaderProps = Readonly<{
  title: string;
}>;

function ContentHeader({ title }: ContentHeaderProps): ReactElement {
  return <div className="text-2xl font-semibold text-navy-500 md:text-4xl">{title}</div>;
}

export default ContentHeader;
