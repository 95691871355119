import { BasicServiceLineSchema } from "@/gen/zod/basicServiceLineSchema";
import { PlatformSchema } from "@/gen/zod/platformSchema";
import { SyncError } from "@/models/error";

export enum SubuserChoiceStatus {
  Advisory = "advisory",
  Start = "start",
  OptedIn = "opt-in",
  OptedOut = "opt-out",
}

export enum SubuserStrategy {
  Manual = "manual",
  Automated = "automated",
  Unsupported = "unsupported",
  Unspecified = "unspecified",
}

export interface AuthState {
  oauthParams: OAuthParams;
  authenticationStatus: AuthenticationStatus;
  initTokenParams: InitToken;
  hasInitialized: boolean;
  platform_id: string | null;
}

export interface OAuthParams {
  clientId: string;
  scope: string;
  redirectUri: string;
  state: string;
  codeChallenge: string;
  initToken: string;
  token: string;
}

export interface AuthenticationStatus {
  emailAuthenticated: boolean;
  emailChallenged: boolean | null;
}

export interface InitToken {
  clientId: string;
  companyEin: string | null;
  companyName: string;
  exp: string;
  iat: string;
  platformId: string | null;
  nativeCompanyId: string | null;
  nativeUserId: string | null;
  userEmail: string;
  userName: string | null;
  userPhone: string | null;
}

export interface Profile {
  user: User;
  serviceLines: Array<BasicServiceLineSchema>;
  verified: boolean;
  authorized: boolean;
}

export interface User {
  emailAddress: string;
  name: string | null;
}

export interface BasicPlatform {
  logo: string | null;
  icon: string | null;
  name: string;
  subuserStrategy: SubuserStrategy;
}

export interface SubuserRequest {
  slug: string;
  createTime: string;
}

export interface SyncSessionParams {
  sync_target_id: string;
  platform_id: string;
  platformConfig: PlatformSchema | null;
  // in case of a user error, or recoverable system error, this will inform ui guidance for the user.
  error: SyncError | null;
  // in event of a catastrophic error (such as ws disconnect), this will inform the sync error screen what went wrong.
  terminalError: SyncError | null;
}

export interface Resource {
  resource_id: string;
  prompt_id: string;
  mime_type: string;
  name?: string | null;
}

export interface Progress {
  current: number;
  total: number;
}

export interface FileResource extends Resource {
  data?: File;
  file_id: string;
}

export interface FileTransferItem {
  file?: FileResource;
  progress?: Progress;
  isUploading: boolean;
  error?: string;
}
