import { ReactElement } from "react";

interface ValuePropositionProps {
  prominent?: boolean; // whether displayed on landing page or secondary component
  landBody: string;
  landSubtitle: string;
}

function ValueProposition({ prominent = true, landBody, landSubtitle }: ValuePropositionProps): ReactElement {
  return (
    <div className="text-navy-500">
      <p className="text-md font-medium md:text-xl">{landSubtitle}</p>
      <p className={`text-md mt-4 max-md:hidden md:mt-10 md:text-lg ${prominent ? "" : "text-xs"}`}>{landBody}</p>
    </div>
  );
}

export default ValueProposition;
