import { ChangeEvent, ClipboardEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState } from "react";

import ErrorAlert from "@/components/Exception/ErrorAlert";

interface PasscodeInputProps {
  inputName: string;
  inputLength?: number;
  regex?: string;
  onComplete?: (code: string) => void;
  error?: string | null;
  disabled?: boolean;
}

function PasscodeInput({
  inputName,
  inputLength = 6,
  regex = "[0-9]",
  error = null,
  onComplete,
  disabled = false,
  ...props
}: PasscodeInputProps): ReactElement {
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(error);
  const inputs = useRef<(HTMLInputElement | null)[]>([]);
  const hiddenCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  // report validity of hidden input to trigger form validation
  useEffect(() => {
    if (hiddenCodeRef.current) {
      hiddenCodeRef.current.reportValidity();
      hiddenCodeRef.current.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }, [code]);

  const handleInputChange = (index: number, ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    if (new RegExp(regex).test(value)) {
      // ensure only letters and digits are entered
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        newCode[index] = value.slice(-1);
        return newCode.join("");
      });
      setErrorMessage(null);
      if (value.length > 0 && inputs.current[index + 1]) {
        inputs.current[index + 1]?.focus();
      } else {
        let emptyInputIndex = -1;
        let lastNonEmptyInputIndex = -1;
        inputs.current.forEach((input, i) => {
          if (input) {
            if (input.value === "") {
              if (emptyInputIndex === -1) {
                emptyInputIndex = i;
              }
            } else {
              lastNonEmptyInputIndex = i;
            }
          }
        });
        if (emptyInputIndex !== -1) {
          inputs.current[emptyInputIndex]?.focus();
        } else if (lastNonEmptyInputIndex !== -1) {
          inputs.current[lastNonEmptyInputIndex]?.blur();
        }
      }
    }
  };

  const handleInputKeyDown = (index: number, ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Backspace") {
      ev.preventDefault();
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        if (index > 0) {
          newCode[index - 1] = "";
          return newCode.join("");
        } else {
          const newCode = "";
          return newCode;
        }
      });
      if (inputs.current[index]?.value) {
        inputs.current[index]?.focus();
      } else {
        inputs.current[index - 1]?.focus();
      }
      setErrorMessage(null);
    }
  };

  const handleInputPaste = (ev: ClipboardEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const pasteData = ev.clipboardData
      .getData("text/plain")
      .replace(new RegExp(`[^${regex.slice(1, -1)}]`, "g"), "")
      .slice(0, inputLength);
    setCode(pasteData.padEnd(inputLength, "0").slice(0, inputLength));
    inputs.current[0]?.focus();
    setErrorMessage(null);
    if (onComplete && code.length === inputLength) {
      onComplete(code);
    }
  };

  const handleInputClick = (index: number) => {
    const emptyInputIndex = inputs.current.findIndex((input) => input && !input.value);
    const lastInputIndex = inputs.current.length - 1;
    if (emptyInputIndex !== -1) {
      inputs.current[emptyInputIndex]?.focus();
    } else if (index === lastInputIndex) {
      inputs.current[index]?.focus();
    } else {
      inputs.current[lastInputIndex]?.focus();
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        {Array.from({ length: inputLength }, (_, index) => (
          <div
            key={index}
            className="relative flex-grow"
          >
            <input
              type="text"
              pattern={regex}
              maxLength={1}
              className={`h-24 w-full border text-center ${
                errorMessage ? "border-orange-500 text-orange-500" : "border-navy-100"
              } ${index > 0 ? "border-l-0 focus:border-l" : ""}`}
              style={{ fontSize: "1.8rem" }}
              value={code[index] ?? ""}
              onChange={(ev) => handleInputChange(index, ev)}
              onKeyDown={(ev) => handleInputKeyDown(index, ev)}
              onPaste={handleInputPaste}
              ref={(input) => (inputs.current[index] = input)}
              onClick={() => handleInputClick(index)}
              autoFocus={index === 0}
              disabled={disabled}
              {...props}
            />
          </div>
        ))}
        <input
          name={inputName}
          ref={hiddenCodeRef}
          defaultValue={code}
          hidden
          required
          pattern={`^${regex}{${inputLength}}$`}
          disabled={disabled}
          data-testid="passcode-input"
          {...props}
        />
      </div>
      {errorMessage && (
        <div className="mt-6">
          <ErrorAlert
            description={errorMessage}
            onClose={() => {
              setErrorMessage(null);
              setCode("");
            }}
          />
        </div>
      )}
    </div>
  );
}

export default PasscodeInput;
