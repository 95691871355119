import { ReactElement, useEffect, useMemo, useState } from "react";

import Button from "@/components/Buttons/Button";
import useAuthStore from "@/store/auth";
import useClientStore from "@/store/client";
import { handleBackToClient } from "@/utils";
import { InitVersion, secondsBetween } from "@/utils";

interface DeadlineIndicatorProps {
  /*
   * Optional start time used to determine what percentage of time remains until the deadline is met.
   * If omitted, the current time when the component is mounted will be used.
   */
  start?: Date;
  /*
   * The deadline.
   */
  deadline: string;
  /*
   * Optional callback which is called when the deadline expires. Useful to avoid scenarios in which both
   * the parent component and the deadline indicator would otherwise need to track the deadline separately.
   */
  onExpiration?: () => void | null;
  /*
   * Whether to show the expired state, which contains some action buttons
   */
  showExpiredState?: boolean;
  /*
   * Optional timer duration. If omitted, the difference between the start and deadline will be used.
   */
  timerDuration?: number | null;
}

function DeadlineIndicator(props: DeadlineIndicatorProps): ReactElement {
  const { start, onExpiration, showExpiredState = true, timerDuration } = props;
  const deadline = useMemo(() => new Date(props.deadline), [props.deadline]);

  const { oauthParams } = useAuthStore();
  const {
    config: { customer_short_name },
  } = useClientStore();

  const [initialTimerSeconds] = useState<number>(timerDuration ?? secondsBetween(start || new Date(), deadline));
  const [timerSeconds, setTimerSeconds] = useState<number>(secondsBetween(new Date(), deadline));

  useEffect(() => {
    const interval = setInterval(function () {
      const seconds = secondsBetween(new Date(), deadline);
      setTimerSeconds(seconds);
      if (seconds < 0) {
        clearInterval(interval);
        if (onExpiration != null) onExpiration();
      }
    }, 500);

    return () => clearInterval(interval);
  }, [deadline, onExpiration]);

  const readableTimeRemaining = () =>
    timerSeconds < 0
      ? "No time"
      : timerSeconds < 60
        ? `${Math.round(timerSeconds)} seconds`
        : `${Math.round(timerSeconds / 60)} minutes`;

  const expiredText = `Your session has expired. Please return to ${customer_short_name} and try again.`;
  const expiredButton = (
    <Button
      label={`Return to ${customer_short_name}`}
      onClick={() =>
        void handleBackToClient(
          false,
          oauthParams.redirectUri,
          oauthParams.state,
          oauthParams.initToken ? InitVersion.V1 : InitVersion.V2,
        )
      }
      style={{ width: "100%", marginBottom: "16px" }}
    />
  );

  const expiredContent = showExpiredState ? (
    <div className="flex w-full flex-col gap-y-1 md:gap-y-2">
      {expiredText}
      {expiredButton}
    </div>
  ) : null;

  const indicatorStyles = {
    width: `${(timerSeconds / initialTimerSeconds) * 100}%`,
    transition: "width 1s linear 0s",
  };

  return (
    <div className="md:text-md flex w-full flex-col gap-y-1 text-xs text-navy-400 max-md:-mb-1 md:gap-y-2">
      <div>{timerSeconds > 0 ? `${readableTimeRemaining()} remaining.` : null}</div>
      <div className={"h-[4px] w-full rounded-full bg-gray-200"}>
        {timerSeconds > 0 && (
          <div
            style={indicatorStyles}
            className={"h-full rounded-full bg-accent-fill"}
          />
        )}
      </div>
      {timerSeconds <= 0 && expiredContent}
    </div>
  );
}

export default DeadlineIndicator;
