import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { ReactElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Button from "@/components/Buttons/Button";
import DeadlineIndicator from "@/components/DeadlineIndicator";
import Markdown from "@/components/Markdown";
import PlatformLogo from "@/components/PlatformLogo";
import { useWebSocketQueue } from "@/contexts/WebSocketQueueContext";
import { InstructionsEventPayload, ResponseEventPayload, SyncEventType } from "@/models/event";
import { Instruction, InstructionType } from "@/models/instructions";
import { SyncEventMessage } from "@/models/message";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useSyncStore from "@/store/sync";
import { UUID } from "@/types";

function AccountDetailValue({ text, copyable }: { text: string; copyable: boolean }): ReactElement {
  const [copied, setCopied] = useState<boolean>(false);

  function onClick() {
    void navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  return (
    <div className="flex flex-row items-center justify-between bg-gray-100 px-2 py-1 text-lg">
      <div className="truncate">{text}</div>
      {copied ? (
        <ClipboardDocumentCheckIcon className="h-5 w-5 shrink-0" />
      ) : copyable ? (
        <ClipboardDocumentIcon
          className="h-5 w-5 shrink-0 hover:cursor-pointer"
          onClick={onClick}
        />
      ) : null}
    </div>
  );
}

function InstructionsMessage({ instructions, deadline }: InstructionsEventPayload): ReactElement {
  const { platformConfig: platform, sync_target_id } = useSyncStore();
  const { setIsWaiting, signalReadyToAdvanceQueue, sendMessage } = useWebSocketQueue();
  const [expired, setExpired] = useState<boolean>(false);

  const onContinue = () => {
    const payload: ResponseEventPayload = {
      eventType: SyncEventType.Response,
      eventId: uuidv4() as UUID,
      responses: [],
    };
    const message = new SyncEventMessage({
      messageId: uuidv4() as UUID,
      syncTargetId: sync_target_id as UUID,
      payload,
    });

    sendMessage(message);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  function renderInstruction(instruction: Instruction): ReactElement {
    switch (instruction.instructionType) {
      case InstructionType.Title:
        if (instruction.text == null) {
          throw new Error("title instruction requires text");
        }
        return (
          <Markdown markdown={instruction.text.trim().startsWith("#") ? instruction.text : `# ${instruction.text}`} />
        );
      case InstructionType.Subtitle:
        if (instruction.text == null) {
          throw new Error("subtitle instruction requires text");
        }
        return (
          <Markdown markdown={instruction.text.trim().startsWith("#") ? instruction.text : `### ${instruction.text}`} />
        );
      case InstructionType.Text:
        if (instruction.text == null) {
          throw new Error("text instruction requires text");
        }
        return <Markdown markdown={instruction.text} />;
      case InstructionType.AccountDetails:
        if (instruction.details == null) {
          throw new Error("account details instruction requires details");
        }
        return (
          <div
            className={`grid w-full grid-flow-row grid-cols-3 gap-y-2 border border-neutral-300 p-4 shadow-lg ${
              platform?.logo ? "pt-2" : ""
            }`}
          >
            {platform?.logo && (
              <div className="col-span-3 flex w-full flex-row justify-center">
                <PlatformLogo platformLogo={platform?.logo} />
              </div>
            )}
            {instruction.details.map((detail) => {
              return (
                <>
                  <div className="col-span-1 self-center">
                    <Markdown markdown={detail.key} />
                  </div>
                  <div className="col-span-2">
                    <AccountDetailValue
                      text={detail.value}
                      copyable={detail.copyable}
                    />
                  </div>
                </>
              );
            })}
          </div>
        );
      default:
        throw new Error("invalid instruction type");
    }
  }

  return (
    <div className="flex h-full w-full flex-col items-center">
      <ContentBody>
        <div className="space-y-4">
          {instructions.map((instruction, i) => (
            <div key={i}>{renderInstruction(instruction)}</div>
          ))}
        </div>
      </ContentBody>
      <ContentFooter>
        <div className="w-full max-md:pb-2">
          {deadline && (
            <div>
              <DeadlineIndicator
                showExpiredState={false}
                deadline={deadline}
                onExpiration={() => {
                  setExpired(true);
                  signalReadyToAdvanceQueue();
                }}
              />
              <div style={{ marginBottom: "16px" }} />
            </div>
          )}
          <Button
            type="submit"
            label="Continue"
            primary
            fullWidth
            disabled={expired}
            onClick={onContinue}
          />
        </div>
      </ContentFooter>
    </div>
  );
}

export default InstructionsMessage;
