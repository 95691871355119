import { EnvelopeIcon, ListBulletIcon, LockClosedIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import { ReactElement } from "react";

import BasisLogo from "@/components/BasisLogo";

type AboutBasisLink = Readonly<{
  title: string;
  icon: ReactElement;
  url: string;
}>;

const links: ReadonlyArray<AboutBasisLink> = [
  {
    title: "Terms of Service",
    icon: (
      <ListBulletIcon
        className="h-3 w-3"
        title="Terms of Service"
      />
    ),
    url: "https://www.usebasis.co/terms-of-service",
  },
  {
    title: "Privacy Policy",
    icon: (
      <ShieldCheckIcon
        className="h-3 w-3"
        title="Privacy Policy"
      />
    ),
    url: "https://www.usebasis.co/privacy-policy",
  },
  {
    title: "Security",
    icon: (
      <LockClosedIcon
        className="h-3 w-3"
        title="Security"
      />
    ),
    url: "https://security.usebasis.co/",
  },
  {
    title: "Contact Us",
    icon: (
      <EnvelopeIcon
        className="h-3 w-3"
        title="Contact Us"
      />
    ),
    url: "mailto:support@usebasis.co",
  },
];

interface AboutBasisProps {
  header?: "text" | "logo";
  alignment?: "left" | "right";
}

function AboutBasis({ header = "text", alignment = "left" }: AboutBasisProps): ReactElement {
  return (
    <div className="items-end space-y-4 text-xs">
      {header === "text" ? (
        <h4 className={`w-full font-bold text-neutral-950 ${alignment === "right" ? "text-end" : ""}`}>ABOUT BASIS</h4>
      ) : (
        <BasisLogo size="lg" />
      )}
      <ul className="space-y-4">
        {links.map((link) => (
          <li
            key={link.title}
            className={`flex items-center ${alignment === "left" ? "flex-row" : "flex-row-reverse"} gap-x-2`}
          >
            {link.icon}
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-neutral-700 hover:text-neutral-950"
            >
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AboutBasis;
