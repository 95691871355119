import { UseQueryResult, useQuery } from "@tanstack/react-query";

import api from "@/api/api";
import {
  BasicServiceLineSchema,
  BasicSyncResultSchema,
  ClientConfigSchema,
  GetPlatformResponseSchema,
  GetPlatformsQueryParamsSchema,
  PlatformSchema,
  ResourceFromOrmSchema,
} from "@/gen";

export function useGetPlatforms(queries: GetPlatformsQueryParamsSchema): UseQueryResult<PlatformSchema[], Error> {
  return useQuery({
    queryKey: ["platforms", queries],
    queryFn: async ({ signal }) => {
      const resp = await api.getPlatforms({
        queries,
        signal,
      });
      return resp.platforms;
    },
  });
}

export function useGetPlatform(platformId: string | null): UseQueryResult<GetPlatformResponseSchema, Error> {
  return useQuery({
    queryKey: ["platform", platformId],
    queryFn: async ({ signal }) => {
      if (platformId == null) return null;
      return await api.getPlatform({
        params: { platform_path: platformId },
        signal,
      });
    },
    enabled: !!platformId,
    throwOnError: true,
  });
}

export function useGetSyncResults(): UseQueryResult<BasicSyncResultSchema[], Error> {
  return useQuery({
    queryKey: ["sync", "results"],
    queryFn: async ({ signal }) => {
      const resp = await api.getSyncResults({ signal });
      return resp.results;
    },
  });
}

export function useGetSyncResult(syncTargetId: string): UseQueryResult<BasicSyncResultSchema, Error> {
  return useQuery({
    queryKey: ["sync", syncTargetId, "result"],
    queryFn: async ({ signal }) => {
      return await api.getSyncResult({ params: { sync_target_id: syncTargetId }, signal });
    },
    throwOnError: true,
  });
}

export function useGetResources(
  syncTargetId: string,
  promptId: string,
): UseQueryResult<ResourceFromOrmSchema[], Error> {
  return useQuery({
    queryKey: ["resources", syncTargetId, promptId],
    queryFn: async ({ signal }) => {
      const resp = await api.getResources({
        params: { sync_target_id: syncTargetId },
        queries: { prompt_id: promptId },
        signal,
      });
      return resp.resources;
    },
    throwOnError: true,
  });
}

export function useGetClientConfig(
  options: {
    enabled: boolean;
    throwOnError?: boolean;
  } = { enabled: true, throwOnError: true },
): UseQueryResult<ClientConfigSchema, Error> {
  return useQuery({
    queryKey: ["client"],
    queryFn: async () => {
      const resp = await api.getClient();
      return resp.config;
    },
    ...options,
  });
}

export function useGetServiceLines(isHealthy: boolean): UseQueryResult<BasicServiceLineSchema[], Error> {
  return useQuery({
    queryKey: ["service_lines", isHealthy],
    queryFn: async () => {
      const resp = await api.getServiceLines({ queries: { is_healthy: isHealthy } });
      return resp.service_lines;
    },
  });
}
