import { ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Button from "@/components/Buttons/Button";
import GoBackButton from "@/components/Buttons/GoBackButton";
import ContentHeader from "@/components/ContentHeader";
import { PaddedPlatformLogo } from "@/components/PlatformLogo";
import { OngoingConnectivityChoiceProps } from "@/components/SyncMessages/SyncResultsAndSubUserDecision";
import { useBackButton } from "@/contexts/BackButtonContext";
import { useWebSocketQueue } from "@/contexts/WebSocketQueueContext";
import { SubuserChoiceStatus } from "@/interfaces";
import { ChoiceEventPayload, SyncEventType, UserDecisionType } from "@/models/event";
import { SyncEventMessage } from "@/models/message";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useClientStore from "@/store/client";
import useSyncStore from "@/store/sync";
import { UUID } from "@/types";

function OngoingConnectivityAdvisory({
  setSubuserChoice,
  subuserChoice,
  deadlineIndicator,
  disabled = false,
}: OngoingConnectivityChoiceProps): ReactElement {
  const {
    config: { customer_short_name },
  } = useClientStore();
  const { platformConfig: platform } = useSyncStore();
  const { sendMessage, syncTargetId, setIsWaiting, signalReadyToAdvanceQueue } = useWebSocketQueue();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { setBackButtonControl } = useBackButton();

  if (platform == null) throw Error("unexpected null platform");

  const onOptIn = () => {
    const payload = {
      eventType: SyncEventType.Choice,
      eventId: uuidv4() as UUID,
      choice: true,
      decisionType: UserDecisionType.CreateSubUser,
    } as ChoiceEventPayload;
    const message = new SyncEventMessage({ messageId: uuidv4() as UUID, syncTargetId, payload });

    setSubmitted(true);
    sendMessage(message);
    setSubuserChoice(SubuserChoiceStatus.OptedIn);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  const onOptOut = () => {
    const payload = {
      eventType: SyncEventType.Choice,
      eventId: uuidv4() as UUID,
      choice: false,
      decisionType: UserDecisionType.CreateSubUser,
    } as ChoiceEventPayload;
    const message = new SyncEventMessage({ messageId: uuidv4() as UUID, syncTargetId, payload });

    setSubmitted(true);
    sendMessage(message);
    setSubuserChoice(SubuserChoiceStatus.OptedOut);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  useEffect(() => {
    setBackButtonControl({
      showReturnToClient: true,
      showBackButton: true,
      goBackCallback: () => setSubuserChoice(SubuserChoiceStatus.Start),
    });
  }, [setBackButtonControl, setSubuserChoice]);

  return (
    <div className="flex h-full w-full flex-col items-center">
      <ContentBody>
        <ContentHeader title="Are you sure?" />
        <div className="mt-4 md:mt-10">
          <div className="text-md flex flex-col gap-y-4 text-navy-500 md:gap-y-5 md:text-lg">
            <p>{customer_short_name} needs up-to-date account data.</p>
            <p>
              To do this, Basis will add an authorized user to your {platform.display_name} account with read-only
              access. You will always be able to delete that user from your bank&apos;s online portal.
            </p>
            <p>Opting out of updates will disrupt your {customer_short_name} service.</p>
          </div>
        </div>
        <PaddedPlatformLogo
          logo={platform.logo}
          displayName={platform.display_name}
        />
      </ContentBody>
      <ContentFooter>
        {deadlineIndicator}
        <div className="flex w-full flex-col gap-y-2 md:gap-y-4">
          <Button
            primary
            fullWidth
            label="Enable updates"
            onClick={() => onOptIn()}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.OptedIn}
          />
          <Button
            fullWidth
            label="Do not enable updates"
            onClick={() => onOptOut()}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.OptedOut}
          />
        </div>
        <div className="w-full max-md:hidden">
          <GoBackButton onClick={() => setSubuserChoice(SubuserChoiceStatus.Start)} />
        </div>
      </ContentFooter>
    </div>
  );
}

export default OngoingConnectivityAdvisory;
