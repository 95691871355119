import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import ErrorAlert from "@/components/Exception/ErrorAlert";
import FormTextField from "@/components/FormTextField";
import PlatformIcon from "@/components/PlatformIcon";
import { PlatformSchema } from "@/gen/zod/platformSchema";
import { useGetPlatforms } from "@/hooks/useApi";
import useClientStore from "@/store/client";

function DummyPlatform() {
  return (
    <div className="flex animate-pulse items-center justify-between border-1 border-navy-100 p-4">
      <div className="flex items-center gap-x-4 overflow-hidden">
        <div className="h-12 w-12 rounded-full bg-navy-100" />
        <div className="overflow-hidden">
          <div className="mb-2 h-2 w-72 rounded-full bg-navy-100" />
          <div className="h-2 w-52 rounded-full bg-navy-100" />
        </div>
      </div>
    </div>
  );
}

function PlatformSearchListLoadingSkeleton() {
  return (
    <div className="flex w-full flex-col gap-y-2">
      <DummyPlatform />
      <DummyPlatform />
      <DummyPlatform />
    </div>
  );
}

function PlatformSearchListError() {
  return (
    <ErrorAlert
      description={"Something went wrong."}
      remediation={
        <>
          Please{" "}
          <button
            onClick={() => window.location.reload()}
            className="underline underline-offset-2 hover:text-gray-700"
          >
            refresh
          </button>{" "}
          the page.
        </>
      }
    />
  );
}

function RefineSearchMessage({ title }: { title: string }): JSX.Element {
  return (
    <div className="mt-2 flex w-full flex-col space-y-2 text-center text-sm text-gray-700 md:mt-6 md:space-y-4">
      <div className="font-semibold">{title}</div>
      <div>Try searching by URL (e.g. citi.com)</div>
    </div>
  );
}

function PlatformButton({ platform, onSelect }: { platform: PlatformSchema; onSelect: () => void }) {
  return (
    <button
      onClick={() => onSelect()}
      className="flex w-full cursor-pointer items-center justify-start border-1 border-navy-100 p-2 hover:border-accent-fill md:p-4"
    >
      <div className="mr-2 shrink-0 md:mr-4">
        <PlatformIcon icon={platform.icon} />
      </div>
      <div className="min-w-0 grow overflow-x-hidden">
        <div className="flex w-full flex-col items-start justify-center">
          <div className="truncate font-medium">{platform.display_name}</div>
          <div className="truncate text-sm text-gray-500">{platform.homepage_url}</div>
        </div>
      </div>
    </button>
  );
}

interface PlatformSearchListProps {
  searchText: string;
  platforms: PlatformSchema[];
  onSelect: (p: PlatformSchema) => void;
}

function PlatformSearchList({ searchText, platforms, onSelect }: PlatformSearchListProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [maskStyle, setMaskStyle] = useState<{ maskImage: string }>({ maskImage: "" });

  const handleScroll = useCallback(() => {
    const target = scrollRef.current;
    if (target != null) {
      const fullGradientThreshold = 200;

      const distanceFromTop = target.scrollTop;
      const distanceFromBottom = Math.trunc(target.scrollHeight - target.scrollTop - target.clientHeight);

      const topGradientStrength = Math.min(distanceFromTop / fullGradientThreshold, 1);
      const bottomGradientStrength = Math.min(distanceFromBottom / fullGradientThreshold, 1);

      const topGradientStop = Math.trunc(Math.max(0, 15 * topGradientStrength));
      const bottomGradientStop = Math.trunc(Math.min(100, 100 - 15 * bottomGradientStrength));

      if (distanceFromTop < 1 && distanceFromBottom < 1) {
        setMaskStyle({ maskImage: "" });
      } else {
        setMaskStyle({
          maskImage: `linear-gradient(to bottom, transparent, black ${topGradientStop}%, black ${bottomGradientStop}%, transparent)`,
        });
      }
    }
  }, []);

  useEffect(() => handleScroll(), [platforms, handleScroll]);

  return (
    <div className="flex h-full min-h-0 w-full flex-col space-y-2">
      {searchText === "" && <div className="text-xs font-light text-gray-700">Most popular banks</div>}
      {searchText !== "" && platforms.length === 0 && (
        <RefineSearchMessage title={`No banks found matching ${searchText}`} />
      )}
      <div
        className="flex grow flex-col space-y-2 overflow-y-scroll"
        style={maskStyle}
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {platforms.map((platform: PlatformSchema) => (
          <PlatformButton
            key={platform.platform_id}
            platform={platform}
            onSelect={() => onSelect(platform)}
          />
        ))}
      </div>
      <div className="shrink-0">
        {searchText !== "" && platforms.length > 0 && <RefineSearchMessage title="Don't see your bank?" />}
      </div>
    </div>
  );
}

interface PlatformSearchSelectorProps {
  onSelect: (p: PlatformSchema) => void;
  query?: string;
}

function PlatformSearchSelector({ onSelect, query = "" }: PlatformSearchSelectorProps) {
  const {
    config: { default_platform_slugs },
  } = useClientStore();
  const [searchText, _setSearchText] = useState<string>(query);
  const updateURLWithoutRerender = (text: string) => {
    const url = new URL(window.location.href);
    if (text) {
      url.searchParams.set("search", text);
    } else {
      url.searchParams.delete("search");
    }
    window.history.replaceState({}, "", url.toString());
  };

  const debouncedUpdateSearchText = useDebouncedCallback((text: string) => {
    _setSearchText(text);
    updateURLWithoutRerender(text);
  }, 200);

  const {
    data: platforms,
    isPending,
    isError,
  } = useGetPlatforms({
    text: searchText || null,
    slug: searchText === "" ? default_platform_slugs : null,
    limit: 250,
  });

  return (
    <div className="mt-4 flex h-full min-h-0 w-full flex-col items-center space-y-6 overflow-y-auto md:mt-10">
      <div className="w-full shrink-0 pt-4">
        <FormTextField
          fullWidth
          id="search-input"
          name="search-input"
          label="Search by Name or URL"
          defaultValue={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => debouncedUpdateSearchText(e.target.value)}
        />
      </div>
      <div className="w-full grow overflow-y-auto">
        {isError ? (
          <PlatformSearchListError />
        ) : isPending ? (
          <PlatformSearchListLoadingSkeleton />
        ) : (
          <PlatformSearchList
            searchText={searchText}
            platforms={platforms || []}
            onSelect={onSelect}
          />
        )}
      </div>
    </div>
  );
}

export default PlatformSearchSelector;
