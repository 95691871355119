import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import Button from "@/components/Buttons/Button";
import GoBackButton from "@/components/Buttons/GoBackButton";
import ConnectedServiceLine from "@/components/ConnectedServiceLine";
import ContentHeader from "@/components/ContentHeader";
import { useBackButton } from "@/contexts/BackButtonContext";
import useStateNavigate from "@/hooks/useStateNavigate";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useAuthStore from "@/store/auth";
import useClientStore from "@/store/client";
import useUserStore from "@/store/user";
import { InitVersion, handleBackToClient } from "@/utils";

export default function ConnectedServiceLines() {
  const { navigateBack, navigateNext } = useStateNavigate();
  const { oauthParams } = useAuthStore();
  const {
    config: { customer_short_name },
  } = useClientStore();
  const { profile } = useUserStore();
  const { setBackButtonControl } = useBackButton();
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const customerShortName = customer_short_name || "";
  async function onSubmit(): Promise<void> {
    setIsFormLoading(true);
    const version = oauthParams.initToken ? InitVersion.V1 : InitVersion.V2;
    await handleBackToClient(true, oauthParams.redirectUri, oauthParams.state, version);
  }

  const text = profile.authorized
    ? `The following accounts have already been connected. Return to ${customerShortName}, or add another account.`
    : `The following accounts have already been connected. Authorize ${customerShortName} to access data from these accounts, or add another account.`;

  useEffect(() => {
    setBackButtonControl({ showReturnToClient: true, showBackButton: true, goBackCallback: navigateBack });
  }, [navigateBack, setBackButtonControl]);

  return (
    <>
      <ContentBody>
        <ContentHeader title="Connected accounts" />
        <div className="mt-4 md:mt-10">
          <p>{text}</p>
        </div>
        <div className="mb-6 mt-6 flex flex-col gap-y-2 md:mt-10 md:gap-y-4">
          {profile.serviceLines.map((sl, i) => (
            <ConnectedServiceLine
              key={i}
              serviceLine={sl}
            />
          ))}
        </div>
      </ContentBody>
      <ContentFooter>
        <div className="flex w-full flex-col gap-y-2 md:gap-y-4">
          <Button
            primary
            fullWidth
            label={profile.authorized ? `Return to ${customerShortName}` : `Authorize access for ${customerShortName}`}
            loading={isFormLoading}
            disabled={isFormLoading}
            onClick={() => void onSubmit()}
          />
          <Button
            label="Add another account"
            icon={<PlusIcon className="h-6 w-6" />}
            fullWidth
            onClick={() => navigateNext("/platforms")}
          />
        </div>
        <div className="flex w-full max-md:hidden">
          <GoBackButton onClick={() => navigateBack()} />
        </div>
      </ContentFooter>
    </>
  );
}
