import { ReactElement } from "react";

import { convertBase64SVGToImageUrl } from "@/utils";

interface PlatformLogoProps {
  platformLogo: string;
}

function PlatformLogo({ platformLogo }: PlatformLogoProps): ReactElement {
  return (
    <div className="flex h-24 w-96 items-center justify-center pb-2">
      <img
        className="h-auto max-h-full w-auto max-w-full object-contain"
        src={convertBase64SVGToImageUrl(platformLogo)}
        alt="Platform logo"
      />
    </div>
  );
}

interface PaddedPlatformLogoProps {
  logo: string | null;
  displayName: string;
}

export function PaddedPlatformLogo({ logo, displayName }: PaddedPlatformLogoProps): ReactElement {
  return (
    <div className="mt-6 flex w-full justify-center py-6 md:mt-8">
      {logo ? (
        <PlatformLogo platformLogo={logo} />
      ) : (
        <span className="text-lg font-medium text-navy-500">{displayName}</span>
      )}
    </div>
  );
}

export default PlatformLogo;
