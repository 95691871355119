import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

import BasisLogo from "@/components/BasisLogo";
import { ENTRY_PATH } from "@/constants/constants";

function PoweredByBasis(): ReactElement {
  const location = useLocation();
  const showMobileInfoIcon = location.pathname !== ENTRY_PATH;
  return (
    <div className="my-2 flex flex-row items-center space-x-2">
      <span className="md:text-md text-xs text-neutral-600">Powered by</span>
      <div className="md:hidden">
        <BasisLogo
          size="sm"
          link={false}
        />
      </div>
      <div className="max-md:hidden">
        <BasisLogo
          size="md"
          link={false}
        />
      </div>
      <div className="flex md:hidden">
        {showMobileInfoIcon && <InformationCircleIcon className="h-4 w-4 stroke-neutral-800" />}
      </div>
    </div>
  );
}

export default PoweredByBasis;
