import { AnimatePresence } from "motion/react";

import SyncViewController from "@/components/SyncMessages/SyncViewController";
import { useWebSocketQueue } from "@/contexts/WebSocketQueueContext";
import { SyncEventMessage } from "@/models/message";

function SyncDriver() {
  const { getCurrentMessage } = useWebSocketQueue();
  const currentMessage: SyncEventMessage | null = getCurrentMessage();

  return (
    <div className="flex h-full w-full flex-col">
      <AnimatePresence mode="wait">
        <SyncViewController message={currentMessage} />
      </AnimatePresence>
    </div>
  );
}

export default SyncDriver;
