import { motion } from "motion/react";
import { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import LoadingSpinnerPadder from "@/components/Suspense/LoadingSpinnerPadder";
import SyncLoadingSpinner from "@/components/Suspense/SyncLoadingSpinner";
import InstructionsMessage from "@/components/SyncMessages/InstructionsMessage";
import PromptMessage from "@/components/SyncMessages/PromptMessage";
import SyncResultsAndSubUserDecision from "@/components/SyncMessages/SyncResultsAndSubUserDecision";
import { motionProps } from "@/constants/constants";
import { useWebSocketQueue } from "@/contexts/WebSocketQueueContext";
import { MajorSyncErrorType, MinorSyncErrorType } from "@/models/error";
import {
  DecisionEventPayload,
  ErrorEventPayload,
  InstructionsEventPayload,
  PromptEventPayload,
  ReportEventPayload,
  StageCompleteEventPayload,
  SyncEventType,
} from "@/models/event";
import { SyncEventMessage } from "@/models/message";
import useSyncStore from "@/store/sync";
import { handleErrorEvent, handleReportEvent, handleStageCompleteEvent } from "@/sync/handleEvent";

// convenience wrapper to animate into loading state
function MotionSyncLoadingState(): ReactElement {
  const { signalReadyToAdvanceQueue } = useWebSocketQueue();
  const { platformConfig: platform } = useSyncStore();
  if (platform == null) throw Error("unexpected null platform");

  useEffect(() => {
    // runs once component is mounted
    signalReadyToAdvanceQueue();
  }, [signalReadyToAdvanceQueue]);

  // Todo 2024-07-12 BAS-1365: make loading spinner height calculation more robust
  return (
    <motion.div
      {...motionProps}
      key="waiting-network"
      className="flex h-full w-full justify-center"
    >
      <LoadingSpinnerPadder>
        <SyncLoadingSpinner platformName={platform.display_name} />
      </LoadingSpinnerPadder>
    </motion.div>
  );
}

function SyncViewController({ message }: { message: SyncEventMessage | null }): ReactElement {
  const { isConnected, isWaiting, closeWebSocket } = useWebSocketQueue();
  const navigate = useNavigate();
  const { terminalError, platform_id, setError, platformConfig } = useSyncStore();
  if (terminalError) {
    throw new Error(JSON.stringify(terminalError));
  }

  useEffect(() => {
    if (!message || !isConnected) return;
    switch (message.payload.eventType) {
      case SyncEventType.Report: {
        // TODO 2024-08-13: replace gross terminal and process event handlers after sync result endpoint is implemented
        const terminal = handleReportEvent(message.payload as ReportEventPayload);
        if (terminal) {
          closeWebSocket();
          navigate("/summary", { replace: true });
        }
        break;
      }
      case SyncEventType.ResultReady: {
        closeWebSocket();
        navigate("/summary", { replace: true });
        break;
      }
      case SyncEventType.StageComplete: {
        const { payload } = message;
        const terminal = handleStageCompleteEvent(payload as StageCompleteEventPayload, setError);
        if (terminal) {
          closeWebSocket();
          if (platformConfig?.aggregator) {
            const { error } = payload as StageCompleteEventPayload;
            // Assume that if this is an aggregator sync, it is Finicity, and if it is Finicity, invalid credentials
            // are handled within Finicity Connect so an error either means the user declined, or there was some
            // genuine error internal to Finicity.
            if (error && error.majorType == MajorSyncErrorType.User && error.minorType == MinorSyncErrorType.Declined) {
              navigate("/platforms", { replace: true });
            } else {
              // Throwing results in the "Something went wrong" error fallback, which includes a button to retry.
              throw new Error();
            }
          } else {
            navigate(`/platforms/${platform_id || ""}`, { replace: true });
          }
        }
        break;
      }
      case SyncEventType.Error: {
        const { payload } = message;
        handleErrorEvent(payload as ErrorEventPayload, setError);
        break;
      }
      case SyncEventType.Observe: {
        break;
      }
      default:
        break;
    }
  }, [message, closeWebSocket, platform_id, setError, platformConfig, isConnected, navigate]);

  const view: ReactElement = useMemo(() => {
    if (!isConnected || isWaiting) return <MotionSyncLoadingState />;

    switch (message?.payload.eventType) {
      case SyncEventType.Prompt: {
        return (
          <motion.div
            className="h-full w-full"
            {...motionProps}
            key={`prompt-${message.payload.eventId}`}
          >
            <PromptMessage {...(message.payload as PromptEventPayload)} />
          </motion.div>
        );
      }
      case SyncEventType.Decision: {
        const { deadline, eventId } = message.payload as DecisionEventPayload;
        return (
          <motion.div
            className="h-full w-full"
            {...motionProps}
            key={`decision-${eventId}`}
          >
            <SyncResultsAndSubUserDecision deadline={deadline} />
          </motion.div>
        );
      }
      case SyncEventType.Instructions: {
        return (
          <motion.div
            className="h-full w-full"
            {...motionProps}
            key={`instructions-${message.payload.eventId}`}
          >
            <InstructionsMessage {...(message.payload as InstructionsEventPayload)} />
          </motion.div>
        );
      }
      default: {
        return <MotionSyncLoadingState />;
      }
    }
  }, [message, isConnected, isWaiting]);

  return <div className="flex h-full w-full flex-col items-center">{view}</div>;
}

export default SyncViewController;
