import { AnimatePresence, motion } from "motion/react";
import { ReactElement, useState } from "react";

import DeadlineIndicator from "@/components/DeadlineIndicator";
import OngoingConnectivityAdvisory from "@/components/OngoingConnectivity/OngoingConnectivityAdvisory";
import OngoingConnectivityStart from "@/components/OngoingConnectivity/OngoingConnectivityStart";
import { motionProps } from "@/constants/constants";
import { SubuserChoiceStatus } from "@/interfaces";

interface SyncResultsAndUserDecisionProps {
  deadline?: string | null;
}

export interface OngoingConnectivityChoiceProps {
  setSubuserChoice: (event: SubuserChoiceStatus) => void;
  subuserChoice: SubuserChoiceStatus;
  deadlineIndicator: ReactElement | null;
  disabled: boolean;
}

function SyncResultsAndSubUserDecision({ deadline }: SyncResultsAndUserDecisionProps): ReactElement {
  const [subuserChoice, setSubuserChoice] = useState<SubuserChoiceStatus>(SubuserChoiceStatus.Start);

  let decisionScreenContent = null;

  const [deadlineStart] = useState<Date>(new Date());
  const [expired, setExpired] = useState<boolean>(false);
  const deadlineIndicator = deadline ? (
    <>
      <DeadlineIndicator
        start={deadlineStart}
        deadline={deadline}
        onExpiration={() => setExpired(true)}
      />
    </>
  ) : null;

  switch (subuserChoice) {
    // "Ongoing connectivity"
    case SubuserChoiceStatus.Start: {
      decisionScreenContent = (
        <OngoingConnectivityStart
          setSubuserChoice={setSubuserChoice}
          subuserChoice={subuserChoice}
          deadlineIndicator={deadlineIndicator}
          disabled={expired}
        />
      );
      break;
    }
    // "Are you sure"
    case SubuserChoiceStatus.Advisory: {
      decisionScreenContent = (
        <OngoingConnectivityAdvisory
          setSubuserChoice={setSubuserChoice}
          subuserChoice={subuserChoice}
          deadlineIndicator={deadlineIndicator}
          disabled={expired}
        />
      );
      break;
    }
    default: {
      decisionScreenContent = null;
      break;
    }
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        {...motionProps}
        key={subuserChoice}
        className="flex h-full w-full"
      >
        {decisionScreenContent}
      </motion.div>
    </AnimatePresence>
  );
}

export default SyncResultsAndSubUserDecision;
