import { ButtonHTMLAttributes, DetailedHTMLProps, MouseEvent, ReactElement } from "react";

import LoadingSpinner from "@/components/Suspense/LoadingSpinner";

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  primary?: boolean;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  label: string;
  icon?: ReactElement;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}

function Button({
  primary = false,
  disabled = false,
  fullWidth = false,
  loading,
  label,
  icon,
  ...props
}: ButtonProps): ReactElement {
  let classes =
    "flex justify-center items-center p-4 px-6 text-sm font-medium disabled:text-navy-200 disabled:cursor-not-allowed";

  if (primary) {
    classes +=
      " disabled:shadow-md bg-accent-fill disabled:bg-accent-fill-muted text-accent-text disabled:text-accent-text-muted";
  } else {
    classes += " bg-white text-navy-500 border-1 border-navy-100 disabled:border-navy-50";
  }

  if (fullWidth) {
    classes += " w-full";
  }
  return (
    <button
      type="button"
      className={classes}
      disabled={disabled}
      {...props}
    >
      {loading ? (
        <LoadingSpinner
          size={5}
          highContrast
        />
      ) : (
        <div className={icon ? "flex flex-row items-center justify-center" : ""}>
          {icon && <div className="mr-2">{icon}</div>}
          <span>{label}</span>
        </div>
      )}
    </button>
  );
}

export default Button;
