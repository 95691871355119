import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import api from "@/api/api";
import Button from "@/components/Buttons/Button";
import GoBackButton from "@/components/Buttons/GoBackButton";
import ContentHeader from "@/components/ContentHeader";
import ErrorAlert from "@/components/Exception/ErrorAlert";
import FormTextField from "@/components/FormTextField";
import { PaddedPlatformLogo } from "@/components/PlatformLogo";
import LoadingSpinner from "@/components/Suspense/LoadingSpinner";
import LoadingSpinnerPadder from "@/components/Suspense/LoadingSpinnerPadder";
import { useBackButton } from "@/contexts/BackButtonContext";
import { PostConnectSyncRequestSchema, postConnectSyncRequestSchema } from "@/gen";
import { PromptSchema } from "@/gen/zod/promptSchema";
import { useGetPlatform } from "@/hooks/useApi";
import useStateNavigate from "@/hooks/useStateNavigate";
import { MajorSyncErrorType, MinorSyncErrorType } from "@/models/error";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useAuthStore from "@/store/auth";
import useClientStore from "@/store/client";
import useSyncStore from "@/store/sync";
import { getSyncErrorMessage, parseFormData } from "@/utils";

export default function PlatformView(): ReactElement {
  const { navigateNext, navigateBack } = useStateNavigate();
  const { initTokenParams } = useAuthStore();
  const { platformId: pathPlatformId } = useParams<{ platformId: string }>();
  const platformId = pathPlatformId ?? initTokenParams?.platformId;
  const {
    config: { customer_short_name },
  } = useClientStore();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const { setBackButtonControl } = useBackButton();
  const { error, setSyncSession, setError } = useSyncStore();

  const formRef = useRef<HTMLFormElement>(null);
  const aggregatorFormSubmittedRef = useRef<boolean>(false);

  if (!platformId) {
    throw new Error("Platform ID is required");
  }

  const { data: platform, isPending } = useGetPlatform(platformId);

  const platformIsAggregator = platform?.aggregator;

  function onInput(ev: React.FormEvent<HTMLFormElement>): void {
    setIsFormValid(ev.currentTarget.checkValidity());
  }

  function onSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    setIsFormLoading(true);
    const formData = new FormData(ev.currentTarget);
    const credentials = parseFormData(formData);
    startSyncSession(credentials);
    setIsFormLoading(false);
  }

  const syncMutation = useMutation({
    mutationFn: async (body: PostConnectSyncRequestSchema) => {
      return await api.postSync(postConnectSyncRequestSchema.parse(body));
    },
    onMutate: () => {
      aggregatorFormSubmittedRef.current = true;
      setError(null);
    },
    onSuccess: (resp) => {
      setSyncSession({
        ...resp,
        platformConfig: platform || null,
        error: null,
        terminalError: null,
      });
      navigateNext("/sync");
    },
    onError: () => {
      aggregatorFormSubmittedRef.current = false;
      setError({
        majorType: MajorSyncErrorType.Internal,
        minorType: MinorSyncErrorType.Unknown,
        reason: "",
      });
    },
  });

  const startSyncSession = useCallback(
    (credentials: { [key: string]: string | number }) => {
      if (platform && (!platformIsAggregator || !aggregatorFormSubmittedRef.current))
        syncMutation.mutate({ platform_id: platform.platform_id, credentials });
    },
    [platform, syncMutation, platformIsAggregator],
  );

  useEffect(() => {
    if (!platformIsAggregator) {
      setBackButtonControl({ showReturnToClient: true, showBackButton: true, goBackCallback: navigateBack });
    } else if (!aggregatorFormSubmittedRef.current) {
      formRef.current?.requestSubmit();
    }
  }, [navigateBack, platformIsAggregator, setBackButtonControl]);

  return (
    <div className="flex h-full w-full">
      <div className={!platformIsAggregator ? "flex h-full w-full flex-col items-center" : "hidden"}>
        {isPending ? (
          <LoadingSpinnerPadder>
            <LoadingSpinner />
          </LoadingSpinnerPadder>
        ) : (
          <>
            <ContentBody>
              <ContentHeader title="Connect to your bank" />
              <div className="mt-4 md:mt-10">
                <p className="text-md md:text-lg">
                  Please enter your details below. This authorizes Basis to gather your data
                  {customer_short_name && ` on ${customer_short_name}'s behalf`}.
                </p>
              </div>
              {platform && (
                <PaddedPlatformLogo
                  logo={platform.logo}
                  displayName={platform.display_name}
                />
              )}
              <form
                className="replay-redact mt-2 flex flex-col gap-y-6 md:mb-4 md:mt-8 md:gap-y-8"
                ref={formRef}
                onInput={(ev) => onInput(ev)}
                onSubmit={(ev) => onSubmit(ev)}
              >
                {platform?.credential_prompts.map((prompt: PromptSchema) => {
                  if (prompt.prompt_type === "text") {
                    return (
                      <FormTextField
                        key={prompt.prompt_id}
                        name={prompt.prompt_id}
                        pattern="^.+$"
                        label={prompt.label}
                        defaultValue={prompt.default_response || ""}
                        readOnly={prompt.default_response !== null}
                        type="text"
                        required
                        fullWidth
                      />
                    );
                  } else if (prompt.prompt_type === "numeric") {
                    return (
                      <FormTextField
                        key={prompt.prompt_id}
                        name={prompt.prompt_id}
                        label={prompt.label}
                        defaultValue={prompt.default_response || ""}
                        readOnly={prompt.default_response !== null}
                        type="number"
                        required
                        fullWidth
                      />
                    );
                  } else if (prompt.prompt_type === "password") {
                    return (
                      <FormTextField
                        key={prompt.prompt_id}
                        name={prompt.prompt_id}
                        label={prompt.label}
                        type="password"
                        required
                        fullWidth
                        autoComplete="current-password"
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                <button
                  type="submit"
                  className="hidden"
                />
              </form>
            </ContentBody>
            <ContentFooter>
              <Button
                label="Continue"
                primary
                fullWidth
                disabled={!isFormValid || isFormLoading || isPending}
                loading={isPending}
                icon={<LockClosedIcon className="h-5 w-5" />}
                onClick={() => formRef?.current?.requestSubmit()}
              />
              {error && (
                <div className="mt-2">
                  <ErrorAlert
                    description={getSyncErrorMessage(error)}
                    onClose={() => setError(null)}
                  />
                </div>
              )}
              <div className="w-full max-md:hidden">
                <GoBackButton
                  onClick={() => {
                    setError(null);
                    navigateBack();
                  }}
                />
              </div>
            </ContentFooter>
          </>
        )}
      </div>
    </div>
  );
}
