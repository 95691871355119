import * as Sentry from "@sentry/react";
import {
  Connect,
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectRouteEvent,
} from "connect-web-sdk";
import { ReactElement, useCallback, useEffect, useRef } from "react";

import useAsyncError from "@/hooks/useAsyncError";
import { PromptResponseType } from "@/models/prompt";

interface ConnectUserEvent {
  action?: string;
  institutionLoginId?: number;
  [key: string]: unknown;
}

interface FinicityPromptResult {
  result: "success" | "failure" | "cancel";
  institution_login_id?: number | null;
}

interface FinicityWrapperProps {
  promptId: string;
  aggregatorUrl: string;
  formRef: React.RefObject<HTMLFormElement>;
}

function FinicityWrapper({ promptId, aggregatorUrl, formRef }: FinicityWrapperProps): ReactElement {
  const debug = import.meta.env.VITE_REACT_APP_HOST_ENV == "local";
  const asyncThrow = useAsyncError();
  const institutionLoginIdRef = useRef<number>();
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const submitResult = useCallback(
    (result: FinicityPromptResult) => {
      if (hiddenInputRef.current && formRef.current) {
        hiddenInputRef.current.value = JSON.stringify(result);
        hiddenInputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
        formRef.current.requestSubmit();
      }
    },
    [hiddenInputRef, formRef],
  );

  const openFinicityConnect = useCallback(() => {
    const opts: ConnectOptions = {
      overlay: "rgba(199,201,199, 0.5)",
      selector: "#aggregator-connect",
    };

    const evHandlers: ConnectEventHandlers = {
      onDone: (ev: ConnectDoneEvent) => {
        if (debug) console.log("onDone", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          level: "info",
          data: { event: ev },
        });
        if (ev.code === 200 && institutionLoginIdRef.current) {
          const result: FinicityPromptResult = {
            result: "success",
            institution_login_id: institutionLoginIdRef.current,
          };
          submitResult(result);
        } else {
          const result: FinicityPromptResult = {
            result: "failure",
          };
          submitResult(result);
        }
      },
      onCancel: (ev: ConnectCancelEvent) => {
        if (debug) console.log("onCancel", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          level: "info",
          data: { event: ev },
        });
        const result: FinicityPromptResult = {
          result: "cancel",
        };
        submitResult(result);
      },
      onError: (ev: ConnectErrorEvent) => {
        if (debug) console.log("onError", ev);
        Sentry.captureException(new Error("Finicity Connect onError"), { extra: { event: ev } });
        const result: FinicityPromptResult = {
          result: "failure",
        };
        submitResult(result);
      },
      onRoute: (ev: ConnectRouteEvent) => {
        if (debug) console.log("onRoute", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          level: "info",
          data: { event: ev },
        });
      },
      onUser: (ev: ConnectUserEvent) => {
        if (debug) console.log("onUser", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          level: "info",
          data: { event: ev },
        });
        if ((ev.action === "AddAccounts" || ev.action === "OAuthAddAccounts") && ev.institutionLoginId) {
          institutionLoginIdRef.current = ev.institutionLoginId;
        }
      },
      onLoad: () => {
        if (debug) console.log("loaded");
        Sentry.addBreadcrumb({ category: "finicity", message: "Finicity Connect onLoad", level: "info" });
      },
    };

    try {
      Connect.launch(aggregatorUrl, evHandlers, opts);
    } catch (e) {
      asyncThrow(e);
    }
  }, [debug, asyncThrow, aggregatorUrl, submitResult]);

  useEffect(() => {
    if (!institutionLoginIdRef.current) void openFinicityConnect();
    return () => {
      Connect.destroy();
    };
  }, [openFinicityConnect, institutionLoginIdRef]);

  return (
    <div className="flex h-[800px] grow">
      <div
        id="aggregator-connect"
        className="relative mx-auto flex h-full w-full max-w-2xl"
      />
      <input
        ref={hiddenInputRef}
        type="hidden"
        name={promptId}
        required
        data-response-type={PromptResponseType.Scalar}
      />
    </div>
  );
}

export default FinicityWrapper;
