import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ReactElement } from "react";

import ErrorAlert from "@/components/Exception/ErrorAlert";
import { BasicSyncResultSchema } from "@/gen";
import { useGetSyncResults } from "@/hooks/useApi";

export function AccountsSummary({ syncResult }: { syncResult: BasicSyncResultSchema }): ReactElement {
  const { accounts, subuser_authorization, subuser_request } = syncResult;

  const { icon, title, content } = accounts.length
    ? {
        icon: <CheckCircleIcon className="h-6 w-6 text-green-500" />,
        title: "Data retrieved",
        content: accounts.map((a, i) => (
          <div key={i}>
            {[a.display_name, a.account_mask && `****${a.account_mask}`].filter((e) => e).join(" ") || `Account ${i}`}
          </div>
        )),
      }
    : subuser_request || subuser_authorization
      ? {
          icon: <ClockIcon className="h-6 w-6 text-amber-500" />,
          title: "Data retrieval pending",
          content: "Your account data will be available soon.",
        }
      : {
          icon: <ExclamationCircleIcon className="h-6 w-6 text-red-600" />,
          title: "Data not retrieved",
          content: "Please add another account or try again later.",
        };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center space-x-2.5">
        {icon}
        <span className="text-sm font-semibold text-navy-500 md:text-lg">{title}</span>
      </div>
      <div className="ml-8 pl-0.5 text-sm text-navy-300 md:text-lg">{content}</div>
    </div>
  );
}

export function SubuserSummary({ syncResult }: { syncResult: BasicSyncResultSchema }): ReactElement {
  const { subuser_authorization, subuser_request } = syncResult;

  const { icon, title, content } = subuser_authorization
    ? {
        icon: <CheckCircleIcon className="h-6 w-6 text-green-500" />,
        title: "Updates enabled",
        content: "New transactions will be retrieved when available.",
      }
    : subuser_request
      ? {
          icon: <ClockIcon className="h-6 w-6 text-amber-500" />,
          title: "Updates pending",
          content:
            "New transactions will be updated once the user you created is active. If you've already created the user, no further action is necessary.",
        }
      : {
          icon: <ExclamationCircleIcon className="h-6 w-6 text-red-600" />,
          title: "Data will not update",
          content: "Please try again to enable retrieval of new transactions.",
        };

  return (
    <div className="text-sm md:text-lg">
      <div className="flex flex-row items-center space-x-2.5">
        {icon}
        <span className="font-semibold text-navy-500">{title}</span>
      </div>
      <div className="ml-9 text-pretty text-navy-300">{content}</div>
    </div>
  );
}

function SyncResultSummaryLoadingSkeleton() {
  return (
    <div className="flex w-full animate-pulse flex-col justify-between space-y-6 rounded border-1 border-navy-100 p-4 py-6">
      <div className="h-4 w-48 rounded-full bg-navy-100" />
      <div className="flex flex-row space-x-4">
        <div className="h-5 w-5 rounded-full bg-navy-100" />
        <div className="flex flex-col space-y-4">
          <div className="mt-1 h-3 w-72 rounded-full bg-navy-100" />
          <div className="h-2 w-36 rounded-full bg-navy-100" />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="h-5 w-5 rounded-full bg-navy-100" />
        <div className="flex flex-col space-y-4">
          <div className="mt-1 h-3 w-56 rounded-full bg-navy-100" />
          <div className="h-2 w-64 rounded-full bg-navy-100" />
          <div className="h-2 w-24 rounded-full bg-navy-100" />
        </div>
      </div>
    </div>
  );
}

interface SyncResultSummaryProps {
  syncTargetId?: string;
  hideOngoingConnectivity?: boolean;
}

function SyncResultSummary({ syncTargetId, hideOngoingConnectivity = false }: SyncResultSummaryProps): ReactElement {
  const { data: results, isFetching, isError } = useGetSyncResults();

  return isFetching ? (
    <SyncResultSummaryLoadingSkeleton />
  ) : isError ? (
    <ErrorAlert description="We are unable to display your results." />
  ) : (
    <div className="flex h-full w-full flex-col items-center justify-start gap-y-4">
      {(results || [])
        .filter((r) => !syncTargetId || syncTargetId == r.sync_target_id)
        .map((result, i) => (
          <div
            key={i}
            className="flex w-full flex-col rounded border border-navy-100 p-2 md:p-4"
          >
            <h2 className="text-md mb-1 font-bold text-navy-500 md:mb-2 md:text-xl">{result.platform.name}</h2>
            <div>
              <div className="flex flex-col gap-y-2 md:gap-y-4">
                <AccountsSummary syncResult={result} />
                {!hideOngoingConnectivity &&
                  result.sync_target_parameters.create_user &&
                  result.platform.subuser_strategy !== "unsupported" && <SubuserSummary syncResult={result} />}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default SyncResultSummary;
